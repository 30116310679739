.landing-page {
    /* Set the background image and add a dark overlay */
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./hero_background_large.jpeg') no-repeat center center/cover;

    /* Set the height to fill the viewport */
    height: 100vh;

    /* Center the text vertically and horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
    .landing-page {
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./hero_background_small.jpeg') no-repeat center center/cover;
    }
}

@media (min-width: 1200px) {
    .landing-page {
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./hero_background_medium.jpeg') no-repeat center center/cover;
    }
}

.landing-text {
    font-family: 'Inconsolata', monospace;
    color: #d4af37;
    font-size: 2rem;
    text-align: center;
    padding: 20px;  /* Add some padding for all devices */
}

/* When the screen is 600px or less, increase the padding */
@media (max-width: 600px) {
    .landing-text {
        padding: 30px;
        margin-top: 5vh;
    }
}

.landing-text a {
    /* please generate blue color for links */
    color: #4169E1;
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease; /* Add transition for smooth color change */
}

.landing-text a:hover {
    color: #b19327; /* Slightly darken color on hover */
}